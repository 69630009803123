import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/forms',
    name: 'forms',
    component: () => import('../views/formsPage.vue'),
    beforeEnter: (to, from, next) => {
      !!store.getters.isLoggedIn ? next() : next({ name: 'home' })
      next()
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
