<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols='12'
        md="6"
      >

        <auth />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from "@/components/auth.vue";

export default {
  name: "Home",

  components: {
    auth,
  },
};
</script>
