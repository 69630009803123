<template>
  <v-card class="mt-16">
    <v-card-title>Howdy, have an admin account?</v-card-title>
    <v-card-text
      v-if="checkEmailMsg"
      class='ma-16 pa-16'
    >
      We just sent you an email, follow the link to log in!
    </v-card-text>

    <v-card-text
      v-else
      class='pb-3'
    >

      <v-card-actions>

        <v-btn @click="gSignIn">
          Sign In &nbsp;
          <v-img :src='require("@/assets/google.svg")' />
        </v-btn>

      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
// https://firebase.google.com/docs/auth/web/email-link-auth?authuser=0
import {
  getAuth,
  sendSignInLinkToEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorage,
} from "@/services/localStorageService";
import { mapActions } from "vuex";
import { db } from "@/firebase";
import collections from "@/collections";

const provider = new GoogleAuthProvider();

export default {
  props: {},
  components: {},
  data() {
    return {
      auth: undefined,
      errorMessage: "",
      pending: false,
      checkEmailMsg: false,
    };
  },
  created() {
    this.auth = getAuth();
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.loginSuccess(user, user.accessToken);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  },
  methods: {
    ...mapActions(["setUser", "setToken", "setProfile"]),
    async loginSuccess(user, token) {
      if (
        user.email.includes("@sndplumbing.com") ||
        user.email.includes("sndplumbingllc@gmail.com") ||
        user.email.includes("claycodedev@gmail.com") ||
        user.email.includes("gnikko101@gmail.com") ||
        user.email.includes("jerrywilliams245@gmail.com")
      ) {
        this.setToken("token", token);
        this.setUser(user);
        const profile = await db
          .collection(collections.profile)
          .doc(user.uid)
          .get();

        if (profile.exists) {
          this.setProfile(profile.data());
        } else {
          db.collection(collections.profile).doc(user.uid).set({
            name: user.displayName,
            companyId: 1,
            isAdmin: false,
            createdOn: new Date(),
            uid: user.uid,
          });
        }
        this.$router.push("/forms");
      }
    },

    gSignIn() {
      const result = signInWithPopup(this.auth, provider)
        .then(async (result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          await this.loginSuccess(result.user, credential.accessToken);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
        });
    },
    reauth(email) {
      if (!this.auth.currentUser) return;
      const credential = EmailAuthProvider.credentialWithLink(
        email,
        "http://localhost:8081/sign-in"
        // window.location.href
      );
      // Re-authenticate the user with this credential.
      reauthenticateWithCredential(this.auth.currentUser, credential)
        .then((usercred) => {
          this.setUser(usercred);
          this.$router.push("/forms");
        })
        .catch((err) => {
          deleteLocalStorage("email");
        });
    },
  },
};
</script>
