import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00999d',
        secondary: '#00bfc4',
        accent: '#8c9eff',
        error: '#b71c1c',
        light:'#ccc',
        medium:'#bbb',
        dark:'#777',
      },
    },
  },
})
