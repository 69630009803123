const expiresName = (name) => `${name}-expires`

export function deleteLocalStorage (name) {
  window.localStorage.removeItem(name)
  window.localStorage.removeItem(expiresName(name))
}

export function getLocalStorage (name) {
  const expires = window.localStorage.getItem(expiresName(name))
  if (expires && +expires < new Date().getTime()) {
    deleteLocalStorage(name)
  }
  return window.localStorage.getItem(name)
}
export function setLocalStorage (name, value, expirationDays) {
  window.localStorage.setItem(name, value)
  if (expirationDays) {
    const now = new Date()
    const expires = new Date().setDate(now.getDate() + expirationDays)
    window.localStorage.setItem(expiresName(name), expires.toString())
  }
}
