import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: undefined,
    profile: undefined,
    token: undefined
  },
  getters: {
    getUser:(state => state.user),
    getProfile:(state => state.profile),
    getToken:(state => state.user),
    isLoggedIn:(state => !!state.user)
  },
  mutations: {
    SET_USER:(state,user)=>state.user = user,
    SET_TOKEN:(state,token)=>state.token = token,
    SET_PROFILE:(state,profile)=>state.profile = profile
  },
  actions: {
    setUser:(context,user)=> context.commit('SET_USER',user),
    setProfile:(context,profile)=> context.commit('SET_PROFILE',profile),
    setToken:(context,token)=> context.commit('SET_TOKEN',token)
  },
  modules: {
  }
})
