import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { firestorePlugin } from 'vuefire'
import '@/firebase'
import JsonExcel from "vue-json-excel";

// import 'firebase/database'
Vue.use(firestorePlugin)
Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
