<template>
  <v-app>
    <v-app-bar
      app
      color="light"
      dark
    >
      <div class="d-flex align-center ml-8">
        <v-btn
          to="/"
          icon
          plain
        >

          <v-img
            alt="SND Plumbing Logo"
            class="shrink mr-2"
            contain
            :src="require('@/assets/logo/snd_logo_teal_dark.svg')"
            transition="scale-transition"
            width="90"
          />
        </v-btn>

      </div>

      <v-spacer />

      <v-btn
        v-if="getUser"
        @click='logout'
        outlined
        class="mr-8"
      >
        <span>Logout</span>
      </v-btn>
      <v-btn
        v-if="getUser"
        to="/forms"
        color="secondary"
      >
        <span class="mr-2">Debrief</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "App",
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    logout() {
      const auth = getAuth();

      signOut(auth).then(() => this.$router.push("/"));
    },
  },
};
</script>

<style scoped>
v-main {
  background: #ccc;
}
</style>
