// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';

// Initialize Firebase
const app = firebase.initializeApp({
  apiKey: "AIzaSyC-FBlboRpdUzYpcVkGMd-b_0u56ULd7Kw",
  authDomain: "snd-admin.firebaseapp.com",
  projectId: "snd-admin",
  storageBucket: "snd-admin.appspot.com",
  messagingSenderId: "709861776818",
  appId: "1:709861776818:web:2f00cae16c4713809298ab"
});

const db = app.firestore()


export { db }
